import React from "react";
import {ThemeProvider} from 'styled-components';

import Layout from "../components/layout" 
import SEO from "../components/seo" 

import Navigation from 'sections/Navigation'; 
// import Footer from 'sections/Footer-classic';

import Banner from '../mySections/404banner/index'

import { GlobalStyle } from 'sections/app.style';
import theme from 'theme/app/themeStyles';

const NotFoundPage = () => (
  <ThemeProvider theme={theme}> 
    <GlobalStyle />
     <Layout>
      <SEO title="404: Not found" />
      <Navigation pageName="404" />
      <Banner />
    </Layout>
  </ThemeProvider>
)

export default NotFoundPage;
