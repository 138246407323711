import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";
import Particle from "reusecore/Particle";

import imgHero from 'assets/images/app/404/404.svg';

import BannerSectionWrapper from "./404banner.style";

const Banner404 = () => {
  return (
    <BannerSectionWrapper>
      <Container fullWidthSM>
            <img src={imgHero} alt="" className="img-404"/>
            <SectionTitle
              className="section-title"
              UniWidth="100%"
            >
              <h1>
              Oops!
              </h1>

              <h4>
                The page not found
              </h4>
            </SectionTitle>
            <Link to="/">
              <Button className="banner-btn">
                Go Home
              </Button>
            </Link>
      </Container>
    </BannerSectionWrapper>
  );
};

export default Banner404;
